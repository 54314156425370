import * as React from 'react';
import './OurSolution.scss';
import { BTN_STYLES, Button } from '../Button/Button';
import { useSwiper } from 'swiper/react';
import { useSwiperSlide } from 'swiper/react';
import { navigate } from 'gatsby';
import ROUTES from '../../../constants/routes';
import { OurSolutionProps } from './types';
import { SliderContentData } from '../Slider/types';

const OurSolution = ({ image, title, description, data, path }: OurSolutionProps) => {
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const slideIndex = swiper.realIndex;
  const slideIsActive = swiperSlide.isActive;
  const slidesData = swiper.slides;
  const amountSlides = swiper.slides.length;

  return (
    <section className="ourSolution">
      <div className="ourSolution__container">
        <div className="ourSolution__tablet">
          <img loading="lazy" src={image} width="470px" height="536px" alt="project image" />
        </div>
        <div className="ourSolution__info">
          {amountSlides > 1 && (
            <div className="buttonsWrapper">
              {slidesData.map((_, index) => {
                const paginationBtnIsActive = slideIsActive && index === slideIndex ? 'btnSlider active' : 'btnSlider';

                return <button key={index} className={paginationBtnIsActive} onClick={() => swiper.slideTo(index)} />;
              })}
            </div>
          )}
          <h3>{title}</h3>
          <p>{description}</p>

          <Button
            title="Read full case study"
            className={path === 'empty' ? undefined : BTN_STYLES.BTN_OUTLINE_MEDIUM}
            onClick={() => navigate(ROUTES.WORKS + `${path}`)}
            disabled={path === 'empty'}
          />
          <div className="techStackWrapper">
            {data?.map(({ asset: { url } }: SliderContentData, index: number) => {
              return <img loading="lazy" key={index} width="50px" height="28px" src={url} alt="technology icon" />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolution;

import * as React from 'react';
import './HipaaCompliant.scss';
import SVG_ICONS_TELEMEDICINE from '../../../images/telemedicine/svg';
import { navigate } from 'gatsby';
import { BTN_STYLES, Button } from '../../_Shared/Button/Button';
import { HipaaCompliantData } from './types';

interface HipaaCompliantProps {
  data: HipaaCompliantData;
}

const HipaaCompliant = ({ data }: HipaaCompliantProps) => {
  const title = data.title;
  const description = data.description;
  const hippaTechnologies = data.hippaTechnologies;

  return (
    <section className="compliant">
      <div className="compliant__container">
        <div className="compliant__services">
          <picture className="work_process">
            <source srcSet={SVG_ICONS_TELEMEDICINE.SHIELD} media="(min-width: 1200px)" width="100px" />
            <img loading="lazy" src={SVG_ICONS_TELEMEDICINE.SHIELD} alt="guarantee icon" />
          </picture>
          <h3>{title}</h3>
          <p>{description}</p>
          <Button
            title="Start Project"
            onClick={() => navigate('#yourIdea')}
            className={BTN_STYLES.BTN_PRIMARY_MEDIUM}
          />
        </div>
        <div className="compliant__standards">
          <div className="standards_items">
            {hippaTechnologies.map((item: string, index: number) => {
              return (
                <div key={index} className="HIPAA">
                  {item}
                </div>
              );
            })}
          </div>
          <p>Products we deliver meet international security standards.</p>
        </div>
      </div>
    </section>
  );
};

export default HipaaCompliant;

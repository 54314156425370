import React, { useEffect, useRef } from 'react';
import './DescribeFeatureItem.scss';
import ROUTES from '../../../../constants/routes';
import { Link } from 'gatsby';

interface DescribeFeatureItemProps {
  index: number;
  answers: string[];
  questions: string;
  answerIsActive: string;
  currentIndex: number;
  setCurrentIndex: (newIndex: number) => void;
  isVisible: boolean;
}

const DescribeFeatureItem = ({
  index,
  answers,
  questions,
  answerIsActive,
  currentIndex,
  setCurrentIndex,
  isVisible,
}: DescribeFeatureItemProps) => {
  const featuresRef = useRef() as React.MutableRefObject<HTMLUListElement>;

  useEffect(() => {
    const initHeight = 0;

    if (featuresRef.current === document.querySelector('.active')) {
      featuresRef.current.style.height = `${featuresRef.current.scrollHeight}px`;
    } else {
      featuresRef.current.style.height = `${initHeight}px`;
    }
  });

  return (
    <div className="textWrapper" style={{ padding: index === currentIndex ? '24px 24px 20px' : '20px 24px' }}>
      <div className={index === currentIndex && isVisible ? 'featureProgressBar__wrapper' : ''}>
        <div
          className={
            index === currentIndex && isVisible
              ? 'feature__progressBar feature__progressBarPlay'
              : 'feature__progressBar  feature__progressBarPause'
          }
        />
      </div>
      <div className="describeTitle">
        <p className="describeTitle__title" onClick={() => setCurrentIndex(index)}>
          {questions}
        </p>
        <ul
          ref={featuresRef}
          className={answerIsActive}
          style={{ marginTop: answerIsActive === 'active' ? '8px' : '0px' }}
        >
          {answers.map((title, index: number) => {
            const text = 'contact us!';
            const textIndex = title[0].indexOf(text);
            const titleFirstPart = title[0].substring(0, textIndex);
            const titleLastPart = title[0].substring(textIndex + text.length, title[0].length);
            const titleWithLink = (
              <>
                {titleFirstPart}
                <Link to={ROUTES.CONTACT_US}>{text}</Link>
                {titleLastPart}
              </>
            );

            return (
              <li key={index} className={`answer`}>
                {textIndex < 0 ? title : titleWithLink}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DescribeFeatureItem;

import React from 'react';
import { TypeOfCooperationsProps } from './types';
import './TypeOfCooperations.scss';

const TypeOfCooperations = ({ title, subheading, description, image }: TypeOfCooperationsProps) => {
  return (
    <>
      <div className="cooperation__columns_image">
        <picture className="work_process">
          <source srcSet={image} media="(min-width: 1200px)" width="480px" height="480" />
          <source srcSet={image} media="(min-width: 320px)" width="300" height="300" />
          <img loading="lazy" src={image} alt="work process card" />
        </picture>
      </div>
      <div className="cooperation__columns_header">
        <span>{title}</span>
        <p>{subheading}</p>
      </div>
      <div className="cooperation__columns_content">{description}</div>
    </>
  );
};

export default TypeOfCooperations;

import React from 'react';
import './AccordionWrapper.scss';
import { AccordionWrapperProps } from '../Accordion/types';
import Accordion from '../Accordion/Accordion';

const AccordionWrapper = ({ data }: AccordionWrapperProps) => {
  return (
    <section className="faqWrapper">
      <Accordion data={data} />
    </section>
  );
};

export default AccordionWrapper;

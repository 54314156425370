import React from 'react';
import './Main.scss';
import { Button } from '../Button/Button';
import { navigate } from 'gatsby';
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs';

interface MainProps {
  title: string;
  btnTitle?: string;
  description?: string;
  className?: string;
  classNameButton?: string;
  bottomDescription?: string;
  image?: string;
  onClick?: () => void;
}

export const MAIN_BACKGROUND_STYLES = {
  ABOUT_US: 'aboutUs',
  CASE_STUDY: 'caseStudy',
  INDUSTRIES: 'industries',
  SERVICES: 'services',
  NEW_INDUSTRIES: 'newIndustries',
};

const Main = ({
  title,
  description,
  image,
  className,
  classNameButton,
  btnTitle = 'Estimate  Project',
  bottomDescription,
  onClick = () => navigate('#yourIdea'),
}: MainProps) => {
  return (
    <div className="mainSection__wrapper">
      <section className={className ? `${className} main__wrapper` : className}>
        <div className="main__breadCrumbs">
          <BreadCrumbs />
        </div>
        <div className="main__container">
          <div className="main">
            <h1>{title}</h1>
            {description && <p>{description}</p>}
            <Button className={classNameButton} title={btnTitle} onClick={onClick} />
            {bottomDescription && <span onClick={() => navigate('#caseArticle')}>{bottomDescription}</span>}
          </div>
          {image && (
            <picture>
              <source srcSet={image} media="(min-width: 1200px)" />
              <source srcSet={image} media="(min-width: 320px)" width="300" height="380" />
              <img loading="lazy" src={image} alt="process card" />
            </picture>
          )}
        </div>
      </section>
    </div>
  );
};

export default Main;

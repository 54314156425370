import * as React from 'react';
import Main, { MAIN_BACKGROUND_STYLES } from '../_Shared/Main/Main';
import { BTN_STYLES } from '../_Shared/Button/Button';
import { IndustriesPageData } from '../ServicePage/types';
import HipaaCompliant from './HipaaCompliant/HipaaCompliant';
import FeaturesList from '../_Shared/FeaturesList/FeaturesList';
import BlockWithCard from '../_Shared/BlockWithCard/BlockWithCard';
import Lines from '../_Shared/Lines/Lines';
import DescribeFeature from '../_Shared/DescribeFeature/DescribeFeature';
import Slider from '../_Shared/Slider/Slider';
import OurProcess from '../_Shared/OurProcess/OurProcess';
import Cooperation from '../Home/Cooperation/Cooperation';
import Testimonials from '../_Shared/Testimonials/Testimonials';
import AccordionWrapper from '../_Shared/AccordionWrapper/AccordionWrapper';
import YourIdea from '../_Shared/YourIdea/YourIdea';

const IndustriesPage = ({ data }: IndustriesPageData) => {
  const industriesData = data.sanityIndustries;
  const title = industriesData.title;
  const sliderData = industriesData.caseStudySlider;
  const description = industriesData.description;
  const industryData = industriesData.industryListContent;
  const hippaData = industriesData.hippacompiantContent;
  const featureData = industriesData.featureListContent;
  const blockWithCardData = industriesData.blockWithCardContent;
  const openForm = industriesData.userForm;
  const {
    title: featureTitle,
    features,
    image: {
      asset: { url },
    },
  } = featureData;
  const faqData = industriesData.faqMarkup;
  const ourProcess = industriesData.ourProcess;

  return (
    <>
      <Main
        className={MAIN_BACKGROUND_STYLES.INDUSTRIES}
        title={title}
        description={description}
        classNameButton={BTN_STYLES.BTN_PRIMARY_LARGE}
        btnTitle="Contact us"
      />
      {industryData === null ? <HipaaCompliant data={hippaData} /> : <FeaturesList data={industryData} />}
      <BlockWithCard data={blockWithCardData} title="Why Lunka" />
      <Lines className="line__wrapper_white" />
      <DescribeFeature title={featureTitle} features={features} image={url} />
      <Slider data={sliderData} />
      <OurProcess data={ourProcess} />
      <Cooperation />
      <Testimonials />
      <AccordionWrapper data={faqData} />
      <YourIdea data={openForm} />
    </>
  );
};

export default IndustriesPage;

import React, { FC } from 'react';
import { RelatedStepsData } from '../OurProcess/types';
import './RelatedSteps.scss';

interface RelatedStepsProps {
  steps: RelatedStepsData[];
  title?: string;
}

const RelatedSteps: FC<RelatedStepsProps> = ({ steps, title }) => {
  return (
    <div className="relatedSteps">
      {title && <h3>{title}</h3>}
      <div className="relatedSteps__container">
        {steps.map(({ title, icons, link }, index: number) => {
          const iconImage = icons.image.asset.url;
          const iconAlt = icons.alt;
          const renderText = () => {
            if (link) {
              return (
                <a href={link} target="_blank">
                  {title}
                </a>
              );
            } else {
              return <span>{title}</span>;
            }
          };

          return (
            <div className="relatedSteps__card" key={index}>
              <img src={iconImage} alt={iconAlt} />
              {renderText()}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedSteps;

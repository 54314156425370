import * as React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/_Shared/PageLayout/PageLayout';
import 'normalize.css';
import '../globalStyles/_fonts.scss';
import '../globalStyles/_styles.scss';
import IndustriesPage from '../components/IndustriesPage/IndustriesPage';
import { IndustriesPageData } from '../components/ServicePage/types';

export const query = graphql`
  query MyIndustries($id: String) {
    sanityIndustries(id: { eq: $id }) {
      title
      description
      slug {
        current
      }
      industryListContent {
        title
        industryList {
          title
          answer
          image {
            image {
              asset {
                url
              }
            }
          }
        }
      }
      featureListContent {
        title
        image {
          asset {
            url
          }
        }
        features {
          title
          answer
        }
      }
      hippacompiantContent {
        title
        description
        hippaTechnologies
        image {
          asset {
            url
          }
        }
      }
      blockWithCardContent {
        cardTitle
        cardIcon {
          asset {
            url
          }
        }
      }
      faqMarkup {
        question
        _rawAnswers
      }
      seo {
        title
        description
      }
      userForm {
        managerName
        managerPosition
        description
        image {
          asset {
            url
          }
        }
        openFormLinks {
          title
          image {
            caption
            alt
            image {
              asset {
                url
              }
            }
          }
        }
      }
      ourProcess {
        _rawContent
        image {
          asset {
            url
          }
        }
      }
      caseStudySlider {
        title
        description
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
        technologiesImage {
          asset {
            url
          }
        }
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const Industries = ({ data }: IndustriesPageData) => {
  const { title, description } = data.sanityIndustries.seo;
  const microMarkup = data.sanityIndustries.faqMarkup;
  const { allSanityFooter } = data;

  return (
    <PageLayout footerData={allSanityFooter} markData={microMarkup} title={title} description={description}>
      <IndustriesPage data={data} />
    </PageLayout>
  );
};

export default Industries;

export const styleImgCreator = (currentItem: number, value: number, plus?: boolean) => {
  return !plus
    ? {
        transform: currentItem === value ? 'rotate(360deg)' : '',
        opacity: currentItem === value ? '1' : '0',
        transition: 'all .5s',
      }
    : {
        transform: currentItem === value ? 'rotate(360deg)' : '',
        opacity: currentItem === value ? '0' : '1',
        transition: 'all .5s',
      };
};

export const arrowStyleCreator = (currentItem: number, value: number, plus?: boolean) => {
  return {
    transform: currentItem === value ? 'rotateY(180deg)' : '',
    transition: 'all .5s',
  };
};

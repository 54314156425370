import * as React from 'react';
import OpenForm from '../OpenForm/OpenForm';
import './YourIdea.scss';
import { YourIdeaProps } from './types';

const YourIdea = ({ data }: YourIdeaProps) => {
  const { managerPosition, managerName, description, openFormLinks } = data;
  const image = data.image.asset.url;

  return (
    <div className="yourIdea" id="yourIdea">
      <div className="yourIdea__container">
        <OpenForm title="Implement your idea with Lunka" />
        <div className="yourIdea__wrapper">
          <div className="yourIdea__teammate">
            <div className="teammate_wrapper">
              <img loading="lazy" src={image} alt="team member" />
              <div className="manager">
                <p>{managerName}</p>
                <p>{managerPosition}</p>
              </div>
              <p className="description">{description}</p>
            </div>
          </div>
          <div className="yourIdea__firms">
            {openFormLinks.map(({ title, image }, index: number) => {
              const picture = image.image.asset.url;
              const alt = image.alt;
              const link = image.caption;

              return (
                <a target="_blank" href={link} key={index}>
                  <img loading="lazy" src={picture} alt={alt} />
                  {title}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourIdea;

import React, { useRef } from 'react';
import OpenFormData from '../OpenForm/types';
import './Modal.scss';
import OpenForm from '../OpenForm/OpenForm';

interface ModalProps {
  title?: string;
  data?: OpenFormData[];
  visible: boolean;
  onClose: () => void;
}

const Modal = ({ title = 'Implement your idea with Lunka', onClose, visible }: ModalProps) => {
  const overlayRef = useRef(null);

  return (
    <>
      {visible && (
        <div className="overlay" ref={overlayRef} onClick={(e) => overlayRef.current === e.target && onClose()}>
          <div className="formWrapper">
            <OpenForm onClose={onClose} title={title} />
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

import React from 'react';
import './Feature.scss';
import { FeatureData } from './types';

const Feature = ({ title, answer, image }: FeatureData) => {
  const description = answer[0];
  const featureImage = image.image.asset.url;

  return (
    <div className="feature">
      <div className="feature__content">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <img loading="lazy" src={featureImage} width="240px" height="240px" alt="image" />
    </div>
  );
};

export default Feature;

import React from 'react';
import './Feedback.scss';
import { TestimonialQueryResponse } from '../Testimonials/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination, Autoplay } from 'swiper';
import FeedbackContent from '../FeedbackContent/FeedbackContent';
import 'swiper/css/autoplay';

interface FeedbackProps {
  feedback: TestimonialQueryResponse[];
  dateHide?: boolean;
  className?: string;
  modalIsVisible: (arg: boolean) => void;
}

const Feedback = ({ feedback, className, modalIsVisible }: FeedbackProps) => {
  return (
    <Swiper
      autoplay={{ delay: 7000 }}
      slidesPerView={1}
      spaceBetween={0}
      keyboard={{ enabled: true }}
      modules={[Keyboard, Pagination, Navigation, Autoplay]}
    >
      {feedback.map((item, index) => {
        const {
          companyName,
          customer,
          text,
          avatar: {
            asset: { url },
          },
          date,
        } = item;

        return (
          <SwiperSlide key={index}>
            <FeedbackContent
              modalIsVisible={modalIsVisible}
              className={className}
              companyName={companyName}
              customer={customer}
              date={date}
              text={text}
              url={url}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Feedback;

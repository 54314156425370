import shield from './svg/shield_check.svg';
import contact from './svg/contact.svg';
import snake from './svg/snake.svg';

const SVG_ICONS_TELEMEDICINE = {
  SHIELD: shield,
  CONTACT: contact,
  SNAKE: snake,
};

export default SVG_ICONS_TELEMEDICINE;

import React from 'react';
import '../Feedback/Feedback.scss';
import { BTN_STYLES, Button } from '../Button/Button';
import ReadMore from '../ReadMore/ReadMore';
import { useSwiper } from 'swiper/react';
import { useSwiperSlide } from 'swiper/react';

interface FeedbackContentProps {
  className: string | undefined;
  url: string;
  customer: string;
  companyName: string;
  date: string;
  text: string;
  modalIsVisible: (arg: boolean) => void;
}

const FeedbackContent = ({
  className,
  url,
  customer,
  companyName,
  date,
  text,
  modalIsVisible,
}: FeedbackContentProps) => {
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const slideIndex = swiper.realIndex;
  const slideIsActive = swiperSlide.isActive;
  const slidesData = swiper.slides;
  const amountSlides = swiper.slides.length;

  return (
    <div className={className || 'feedback'}>
      <div className="feedback__leftColumn">
        <img src={url} alt="author" />
      </div>
      <div className="feedback__response">
        {amountSlides > 1 && (
          <div className="buttonsWrapper">
            {slidesData.map((_, index) => {
              const paginationBtnIsActive = slideIsActive && index === slideIndex ? 'btnSlider active' : 'btnSlider';

              return <button key={index} className={paginationBtnIsActive} onClick={() => swiper.slideTo(index)} />;
            })}
          </div>
        )}
        <div className="feedback__wrapper">
          <div className="feedback__head">
            <h5>{customer}</h5>
            <span className="feedback__projectDate">{new Date(date).toLocaleDateString()}</span>
          </div>
          <span className="feedback__projectName">{companyName}</span>
        </div>
        <div>
          <ReadMore className="feedback__text">{text}</ReadMore>
        </div>
        <Button
          title="Estimate Project"
          className={BTN_STYLES.BTN_PRIMARY_MEDIUM}
          onClick={() => modalIsVisible(true)}
        />
      </div>
    </div>
  );
};

export default FeedbackContent;

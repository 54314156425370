import React from 'react';
import './Cooperation.scss';
import TypeOfCooperations from '../TypeOfCooperations/TypeOfCooperations';
import { renderLeftDescription, renderRightDescription } from '../../../constants/solutions';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';

const Cooperation = () => {
  return (
    <section className="cooperation">
      <div className="container">
        <div className="cooperation__columns ">
          <div className="cooperation__columns_box">
            <TypeOfCooperations
              title="Outsourcing"
              image={SVG_ICONS_SHARED.OUTSOURCING}
              description={renderLeftDescription()}
            />
          </div>
          <div className="cooperation__columns_box">
            <TypeOfCooperations
              title="Outstaffing"
              subheading="the process is quite simple"
              image={SVG_ICONS_SHARED.OUTSTAFFING}
              description={renderRightDescription()}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cooperation;

import * as React from 'react';
import './Testimonials.scss';
import Feedback from '../Feedback/Feedback';
import { TestimonialQueryResponse } from './types';
import { graphql, useStaticQuery } from 'gatsby';
import Modal from '../Modal/Modal';
import { useState } from 'react';

const Testimonials = () => {
  const testimonialQueryData = useStaticQuery(graphql`
    query TestimonialQuery {
      allSanityTestimonial {
        nodes {
          companyName
          text
          avatar {
            asset {
              url
            }
          }
          customer
          date
        }
      }
    }
  `);

  const testimonials: TestimonialQueryResponse[] = testimonialQueryData.allSanityTestimonial.nodes;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <section className="testimonials">
      <div className="testimonials__container">
        <h2>Testimonials</h2>
        <Feedback className="feedback oneFeedback" feedback={testimonials} modalIsVisible={setIsVisible} />
        <Modal visible={isVisible} onClose={() => setIsVisible(false)} />
      </div>
    </section>
  );
};

export default Testimonials;

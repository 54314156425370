import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import './DescribeFeature.scss';
import DescribeFeatureItem from './DescribeFeatureItem/DescribeFeatureItem';
import { BTN_STYLES, Button } from '../Button/Button';
import { navigate } from 'gatsby';
import { DescribeFeatureData } from './types';

const DescribeFeature = ({ title, features, image }: DescribeFeatureData) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isFeaturesVisible, setIsFeaturesVisible] = useState<boolean>(false);
  const questionItemNumber = [...Array(features.length).keys()];
  const timerId = useRef<null | number | NodeJS.Timeout>(null);
  const startAnimation = () => {
    const featuresBlock = document.querySelector('.describeFeature__text') as HTMLElement;
    const featuresBlockPosition = featuresBlock?.getBoundingClientRect().top;
    const lastFeaturesCard = featuresBlock?.children[featuresBlock?.children.length - 1];
    const lastFeaturesCardPosition = lastFeaturesCard?.getBoundingClientRect().top;
    const clientViewHeight = document.documentElement.clientHeight;
    const [minTopPosition, maxBottomPosition] = [150, clientViewHeight - 150];
    const isInView = lastFeaturesCardPosition > minTopPosition && featuresBlockPosition < maxBottomPosition;

    if (isInView) {
      if (!timerId.current) {
        timerId.current = window.setTimeout(() => {
          setIsFeaturesVisible(true);
        }, 100);
      }
    } else {
      clearTimeout(timerId.current as NodeJS.Timeout);
      timerId.current = null;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', startAnimation);

    if (isFeaturesVisible) {
      const interval = setInterval(() => {
        if (currentIndex === features?.length - 1) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex((current) => current + 1);
        }
      }, 10000);

      return () => clearInterval(interval);
    }

    return () => window.removeEventListener('scroll', startAnimation);
  }, [currentIndex, isFeaturesVisible]);

  return (
    <section className="describeFeature">
      <div className="describeFeature__container">
        <h2>{title}</h2>
        <div className="describeFeature__content">
          <div className="describeFeature__text">
            {features.map(({ title, answer }, index: number) => {
              const answerIsActive = questionItemNumber[index] === currentIndex ? `active` : 'disabled';

              return (
                <DescribeFeatureItem
                  key={index}
                  index={index}
                  answers={answer}
                  questions={title}
                  answerIsActive={answerIsActive}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  isVisible={isFeaturesVisible}
                />
              );
            })}
          </div>
          <img loading="lazy" src={image} width="670px" height="430px" alt="project" />
        </div>
        <Button className={BTN_STYLES.BTN_PRIMARY_LARGE} title="Contact us" onClick={() => navigate('#yourIdea')} />
      </div>
    </section>
  );
};

export default DescribeFeature;

import * as React from 'react';
import './FeaturesList.scss';
import { FeaturesListData } from '../Feature/types';
import Feature from '../Feature/Feature';
import { BTN_STYLES, Button } from '../Button/Button';
import { navigate } from 'gatsby';

interface FeaturesListProps {
  data: FeaturesListData;
}

const FeaturesList = ({ data }: FeaturesListProps) => {
  const title = data.title;
  const description = data.description;
  const industryList = data.industryList;

  return (
    <section className="featuresList">
      <div className="featuresList__container">
        <div className="featuresList__textWrapper">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="featuresList__featuresWrapper">
          {industryList.map(({ title, answer, image }, index: number) => {
            return <Feature key={index} answer={answer} image={image} title={title} />;
          })}
        </div>
        <Button className={BTN_STYLES.BTN_PRIMARY_LARGE} onClick={() => navigate('#yourIdea')} />
      </div>
    </section>
  );
};

export default FeaturesList;

import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import './Question.scss';
import SVG_ICONS_HOME from '../../../images/home/svg';
import { styleImgCreator, arrowStyleCreator } from '../../../utils/utilsCommon';
import { SubtitleData } from '../Accordion/types';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import usePortableText from '../../../hooks/usePortableText';

interface QuestionProps {
  index: number;
  title: string;
  titlePath?: string;
  subtitles?: SubtitleData[];
  answerIsActive: string;
  currentItem: number;
  setCurrentItem: (status: number) => void;
  questionItemNumber: number[];
  accordionClassName?: string;
}

const Question = ({
  index,
  subtitles,
  title,
  answerIsActive,
  currentItem,
  setCurrentItem,
  questionItemNumber,
  accordionClassName = 'question',
}: QuestionProps) => {
  const currentItemHandler = (title: number) => {
    setCurrentItem(currentItem === title ? 100 : title);
  };

  const ulElementRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [isActive, setIsActive] = useState(false);
  const { renderPortableText } = usePortableText();

  useEffect(() => {
    const initHeight = 0;

    if (ulElementRef.current === document.querySelector('.active.faqBlock')) {
      ulElementRef.current.style.height = `${ulElementRef.current.scrollHeight}px`;
    } else {
      ulElementRef.current.style.height = `${initHeight}px`;
      ulElementRef.current.style.paddingLeft = '0';
    }
  });

  useEffect(() => {
    if (ulElementRef.current.className === 'active faqBlock') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  });

  const renderIcons = () => {
    return accordionClassName !== 'question' ? (
      <img
        loading="lazy"
        src={isActive ? SVG_ICONS_SHARED.ACCORDION_ARROW_UP : SVG_ICONS_SHARED.ACCORDION_ARROW_DOWN}
        alt={isActive ? 'Accordion arrow up' : 'Accordion arrow down'}
        style={arrowStyleCreator(currentItem, questionItemNumber[index], !isActive)}
      />
    ) : (
      <img
        loading="lazy"
        src={isActive ? SVG_ICONS_HOME.MINUS : SVG_ICONS_HOME.PLUS}
        alt={isActive ? 'Icon minus' : 'Icon plus'}
        style={styleImgCreator(currentItem, questionItemNumber[index], !isActive)}
      />
    );
  };

  return (
    <div className={accordionClassName} onClick={() => currentItemHandler(questionItemNumber[index])}>
      <div className="question__wrapper">
        <p>{title}</p>
        <div className="question__icons">{subtitles && renderIcons()}</div>
      </div>
      <div
        ref={ulElementRef}
        className={answerIsActive + ' faqBlock'}
        style={{
          marginTop:
            accordionClassName !== 'question'
              ? subtitles && isActive
                ? '12px'
                : '0'
              : subtitles && isActive
              ? '20px'
              : '0',
        }}
      >
        {renderPortableText(subtitles)}
      </div>
    </div>
  );
};

export default Question;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper';
import OurSolution from '../OurSolution/OurSolution';
import './Slider.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { SliderProps } from './types';

const Slider = ({ data }: SliderProps) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      keyboard={{ enabled: true }}
      modules={[Keyboard, Pagination, Navigation]}
    >
      {data.map(
        (
          {
            mainImage: {
              asset: { url },
            },
            title,
            description,
            technologiesImage,
            slug: { current },
          },
          index: number
        ) => {
          return (
            <SwiperSlide key={index}>
              <OurSolution
                image={url}
                title={title}
                description={description}
                data={technologiesImage}
                path={current}
              />
            </SwiperSlide>
          );
        }
      )}
    </Swiper>
  );
};

export default Slider;

import * as React from 'react';
import './OurProcess.scss';
import { navigate } from 'gatsby';
import { BTN_STYLES, Button } from '../Button/Button';
import ROUTES from '../../../constants/routes';
import { useEffect, useState } from 'react';
import { OurProcessData } from './types';
import usePortableText from '../../../hooks/usePortableText';
import RelatedSteps from '../RelatedSteps/RelatedSteps';

interface OurProcessProps {
  data: OurProcessData;
}

const OurProcess = ({ data }: OurProcessProps) => {
  const [isFoodDeliveryPage, setIsFoodDeliveryPage] = useState<boolean>(false);
  const { renderPortableText } = usePortableText();
  const image = data.image?.asset?.url;
  const processSteps = data.processSteps;
  const rawContent = data._rawContent;

  const renderBtn = (isIndustry: boolean) => {
    return isIndustry ? (
      <Button title="Contact us" className={BTN_STYLES.BTN_PRIMARY_MEDIUM} onClick={() => navigate('#yourIdea')} />
    ) : (
      <Button
        title="Contact us"
        className={BTN_STYLES.BTN_PRIMARY_MEDIUM}
        onClick={() => navigate(ROUTES.CONTACT_US)}
      />
    );
  };

  useEffect(() => {
    if (document.querySelector('.foodDelivery__page')) {
      setIsFoodDeliveryPage(true);
    }
  }, [isFoodDeliveryPage]);

  return (
    <section className="process">
      <div className="process__content">
        <div className="process__content_left">
          {renderPortableText(rawContent)}
          {renderBtn(isFoodDeliveryPage)}
        </div>
        <picture className="work_process">
          <source srcSet={image} media="(min-width: 1200px)" width="480px" height="480" />
          <source srcSet={image} media="(min-width: 320px)" width="300" height="380" />
          <img loading="lazy" src={image} alt="process card" />
        </picture>
      </div>
      {processSteps && <RelatedSteps steps={processSteps} />}
    </section>
  );
};

export default OurProcess;

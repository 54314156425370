import React from 'react';

export const renderLeftDescription = () => {
  return (
    <ul>
      <li>We receive a technical references and form a design for it</li>
      <li>Form a list of questions and approve it with a Client</li>
      <li>Set deadlines and workflows</li>
    </ul>
  );
};

export const renderRightDescription = () => {
  return (
    <ul>
      <li>Then everything is simple — the employee shows himself and control the situation</li>
      <li>We receive a request for a required specialist position</li>
      <li>Technical interview and test an English level</li>
    </ul>
  );
};

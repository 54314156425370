import * as React from 'react';
import { useState } from 'react';
import './Accordion.scss';
import Question from '../Question/Question';
import { AccordionProps } from './types';

const Accordion = ({ data, accordionClassName, title = 'FAQ' }: AccordionProps) => {
  const [currentItem, setCurrentItem] = useState(100);
  const questionItemNumber = [...Array(data.length).keys()];

  return (
    <div className={accordionClassName ? 'burgerMenu__container' : 'faq'}>
      {!accordionClassName && data.length !== 0 && <h2>{title}</h2>}
      {data.map(({ question, _rawAnswers }, index) => {
        const answerIsActive = questionItemNumber[index] === currentItem ? `active` : 'disabled';

        return (
          <Question
            key={index}
            index={index}
            subtitles={_rawAnswers}
            title={question}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            answerIsActive={answerIsActive}
            questionItemNumber={questionItemNumber}
            accordionClassName={accordionClassName}
          />
        );
      })}
    </div>
  );
};

export default Accordion;

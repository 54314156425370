import React, { useState } from 'react';
import './ReadMore.scss';

interface ReadMoreProps {
  children: string;
  className: string;
}

const ReadMore = ({ children, className }: ReadMoreProps) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const sliceContent = isReadMore ? children.slice(0, 350) : children;
  const isContentBigger = isReadMore ? ' ...Read more' : ' Show less';
  const isReadMoreVisible = sliceContent.length >= 350 && isContentBigger;

  return (
    <p className={className}>
      {sliceContent}
      <span className="readMore" onClick={toggleReadMore}>
        {isReadMoreVisible}
      </span>
    </p>
  );
};

export default ReadMore;
